import { createContext } from 'react';

export const backURL         = 'https://cia-pops.fr/api' as const;
export const frontURL        = 'https://www.cia-pops.fr' as const;
export const defaultLanguage = 'en' as const;
export const languages       = [defaultLanguage, 'fr'] as const;
export type  Language        = typeof languages[number];

export class GlobalContextProvider {
    modeTheme      : boolean;
    modeLang       : boolean;
    prefLang       : string;
    translation    : { [key: string]: string };
    translate      : (key: string) => string;
    token          : string;
    dev            : boolean;
    setModeTheme   : (theme: boolean) => void;
    setModeLang    : (lang: boolean) => void;
    setPrefLang    : (lang: Language) => void;
    setTranslation : (translation: { [key: string]: string }) => void;
    setToken       : (token: string) => void;

    constructor (data: any) {
        this.modeTheme      = data.modeTheme      ?? false;
        this.modeLang       = data.modeLang       ?? false;
        this.prefLang       = data.prefLang       ?? "";
        this.translation    = data.translation    ?? {};
        this.translate      = data.translate      ?? (() => {});
        this.token          = data.token          ?? "";
        this.dev            = data.dev            ?? false;
        this.setModeTheme   = data.setModeTheme   ?? (() => {});
        this.setModeLang    = data.setModeLang    ?? (() => {});
        this.setPrefLang    = data.setPrefLang    ?? (() => {});
        this.setTranslation = data.setTranslation ?? (() => {});
        this.setToken       = data.setToken       ?? (() => {});
    }
}

export const globalContext = createContext<GlobalContextProvider>(new GlobalContextProvider({}));
