import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { defaultLanguage, languages, Language, GlobalContextProvider, globalContext } from './script/Values';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import Infobar from './components/Infobar';
import HUD from './components/HUD';
import './styles/General.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Home     = lazy(() => import('./components/pages/Home'));
const Code     = lazy(() => import('./components/pages/Code'));
const Event    = lazy(() => import('./components/pages/Event'));
const News     = lazy(() => import('./components/pages/News'));
const Read     = lazy(() => import('./components/pages/Read'));
const Contacts = lazy(() => import('./components/pages/Contacts'));
const Connect  = lazy(() => import('./components/pages/Connect'));
const Review   = lazy(() => import('./components/pages/Review'));

interface TradType {
    [key: string]: string;
}

const App: React.FC = () => {
    const [__modeTheme, __setModeTheme]    : [boolean,  (b: boolean) => void]  = useState<boolean>(Boolean(Number(localStorage.getItem('modeTheme'))));
    const [__modeLang, __setModeLang]      : [boolean,  (b: boolean) => void]  = useState<boolean>(Boolean(Number(localStorage.getItem('modeLang'))));
    const [__prefLang, __setPrefLang]      : [string,   (s: string) => void]   = useState<string>(localStorage.getItem('prefLang') || 'fr');
    const [__translation, __setTranslation]: [TradType, (d: TradType) => void] = useState<{ [key: string]: string }>({});
    const [__token, __setToken]            : [string,   (s: string) => void]   = useState<string>(sessionStorage.getItem('token') || '');

    const context: GlobalContextProvider = new GlobalContextProvider({
        modeTheme: __modeTheme,
        modeLang: __modeLang,
        prefLang: __prefLang,
        translation: __translation,
        translate: (key: string) => context.translation[key] || key.split('.').pop() || key,
        token: __token,
        dev: true,
        setModeTheme: __setModeTheme,
        setModeLang: __setModeLang,
        setPrefLang: __setPrefLang,
        setTranslation: __setTranslation,
        setToken: __setToken
    });

    useEffect(() => {
        localStorage.setItem('modeTheme', context.modeTheme ? '1' : '0');
    }, [context.modeTheme]);

    useEffect(() => {
        localStorage.setItem('modeLang', context.modeLang ? '1' : '0');
    }, [context.modeLang]);

    useEffect(() => {
        localStorage.setItem('prefLang', context.prefLang);
    }, [context.prefLang]);

    useEffect(() => {
        sessionStorage.setItem('token', context.token);
    }, [context.token]);

    function updateSizes(): void {
        const infobarHeight: number = document.getElementById('infobar')?.offsetHeight || 0;
        document.documentElement.style.setProperty('--infobar-height', `${infobarHeight}px`);
    };

    useEffect(() => {
        updateSizes();
        window.addEventListener('resize', updateSizes);
        return () => {
            window.removeEventListener('resize', updateSizes);
        }
    });

    async function loadTranslations(lang: string): Promise<TradType> {
        if (!languages.includes(lang as Language))
            throw new Error(`Language ${lang} is not supported.`);
        const translations = await import(`./languages/${lang}.json`);
        return translations.default;
    };

    useEffect(() => {
        (async () => {
            context.setTranslation(await loadTranslations(context.modeLang ? context.prefLang : defaultLanguage));
        })();
        (async () => {
            await new Promise(resolve => setTimeout(resolve, 1));
            updateSizes();
        })();
    }, [context.modeLang, context.prefLang]);

    useEffect(() => {
        if (context.dev) console.log('Rendering App');
    });

    return (
        <globalContext.Provider value={context}>
            <Router>
                <input type='checkbox' id='mode-theme' checked={context.modeTheme} readOnly />
                <div id='subroot'>
                    <div id='fixed-background'></div>
                    <Navbar />
                    <main id='main'>
                        <Suspense fallback={<Loading />}>
                            <Routes>
                                <Route path='/' element={<Home />} />
                                <Route path='/code' element={<Code />} />
                                <Route path='/event' element={<Event />} />
                                <Route path='/submit' element={<Review />} />
                                <Route path='/news' element={<News />} />
                                <Route path='/read' element={<Read />} />
                                <Route path='/contacts' element={<Contacts />} />
                                <Route path='/connect' element={<Connect />} />
                            </Routes>
                        </Suspense>
                    </main>
                    <Infobar />
                    <HUD />
                </div>
            </Router>
        </globalContext.Provider>
    );
};

export default App;
