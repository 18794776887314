import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { globalContext, GlobalContextProvider } from '../script/Values';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
    const context: GlobalContextProvider = useContext(globalContext);

    const year: number = new Date().getFullYear();

    useEffect(() => {
        if (context.dev) console.log('Rendering Navbar');
    });

    return (
        <header id='navbar'>
            <Link to='/' className='navbar-logo-link'>
                <img src='/images/logo_cia_marge.svg' />
                <div className='navbar-zero'>
                    <h1>CIA - POPS</h1>
                    <small>{year}</small>
                </div>
            </Link>
            <nav className='navbar-links'>
                <ul>
                    <li>
                        <Link to='/code'>
                            <i className='fas fa-code' />
                            <p className='navbar-second'> {context.translate('page.code')}</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/news'>
                            <i className='fas fa-newspaper' />
                            <p className='navbar-second'> {context.translate('page.news')}</p>
                        </Link>
                    </li>
                    <li>
                        <Link to='/contacts'>
                            <i className='fas fa-address-book' />
                            <p className='navbar-second'> {context.translate('page.contacts')}</p>
                        </Link>
                    </li>
                    <Link to='/connect' className='navbar-links-connect'>
                        <i className='fas fa-user' />
                        <p className='navbar-first'> {context.token ? context.translate('navbar.profile') : context.translate('navbar.signin')}</p>
                    </Link>
                </ul>
            </nav>
        </header>
    );
};

export default Navbar;
